<template>
  <div class="vm-thank-you-wrap">
    <div class="vm-section">
      <v-container>
        <v-row align="center">
          <v-col>
            <h1>Thank you {{ name }}!</h1>
            <p>
              We have no immediate plans to come to your area but should that change we will notify you.
              <br><br>
              Encourage your neighbours to visit our website to show their interest too.
            </p>
            <v-btn rounded outlined dark to="/" class="thank-btn">
              Take me home
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'ThankYou',
  mounted () {
    $('.vuma-menu, .menu-overlay').removeClass('menu-show')
    $('html').css('overflow', 'auto')
    this.scrollTop()
  },
  computed: {
    name () {
      return this.$route.params.customerName || ''
    },
    filteredItems () {
      return this.providers.filter(item => {
        return item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
      })
    },
  },
  methods: {
    scrollTop () {
      let target = 'html, body'

      $(target).stop().animate({
        'scrollTop': $(target).offset().top,
      }, 500, 'swing')
    },
  },
  metaInfo () {
    return {
      title: 'Thank You | Vuma',
      meta: [
        { name: 'description', content: 'Thank You!' },
        { property: 'og:title', content: 'Thank You | Vuma' },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex' },
      ],
    }
  },
}
</script>

<style lang="scss">
.thank-you-page {

  .vuma-header {
    top: 0;
    background: #ee008c !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .vm-thank-you-wrap {
    background: #ee008c;
    color: #fff;
    padding-top: 7rem;
    padding-bottom: 7rem;
    text-align: center;

    h1 {
      margin-bottom: 2rem;
      font-size: 64px;
    }

    p {
      max-width: 450px;
      width: 100%;
      margin: 0 auto 2rem;
    }

    .v-btn.thank-btn {
      transition: all .2s ease;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #ff008e;
      }
    }
  }
}
</style>